/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account': {
    width: 27,
    height: 25,
    viewBox: '0 0 27 25',
    data: '<path pid="0" _fill="#F9F4EC" d="M14.41 1a6 6 0 015.98 6.47l-.413 5.273a5.988 5.988 0 01-2.139 4.138c.945.64 2.116 1.056 3.528 1.246l.36.043c2.811.294 4.321 2.163 4.347 5.258l-2 .017c-.017-2.048-.754-3.032-2.373-3.263l-.183-.023c-2.275-.238-4.142-.973-5.57-2.211a6.01 6.01 0 01-1.952.325h-.355a5.99 5.99 0 01-2.344-.476c-1.45 1.33-3.374 2.114-5.74 2.362l-.183.023c-1.62.231-2.356 1.215-2.373 3.263l-2-.017c.026-3.095 1.536-4.964 4.348-5.258l.36-.043c1.57-.211 2.842-.703 3.837-1.47a5.984 5.984 0 01-1.886-3.914L7.244 7.47A6 6 0 0113.226 1h1.183zm0 2h-1.184a4 4 0 00-3.988 4.313l.414 5.273a4 4 0 003.988 3.687h.355a4 4 0 003.988-3.687l.414-5.273A4 4 0 0014.41 3z"/>'
  }
})
